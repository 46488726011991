// @flow strict
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import Display from '@components/Form/Display'
import warehousesQuery from '@graphql/client/warehouse/query.warehouses.graphql'
import warehousesByIDsQuery from '@graphql/client/warehouse/query.warehousesByIDs.graphql'
import type {
  WarehousesQuery,
  WarehousesQueryVariables,
  WarehouseFilterInput,
  WarehouseSortInput,
} from '@graphql/server/flow'
import useQueryList from '@hooks/useQueryList'

import { CancelButton, SaveButton } from 'components/Buttons'
import { WarehouseCard } from 'components/Cards'
import BaseCard from 'components/Cards/BaseCard'
import GridView from 'components/GridView'
import { Content, SlideViewLayout, SlideViewNavBar } from 'components/Layout'
import {
  EntityIcon,
  Filter,
  Search,
  Sort,
  WarehouseFilterConfig,
  WarehouseSortConfig,
} from 'components/NavBar'
import Selector from 'components/Selector'
import SlideView from 'components/SlideView'
import useFilterSort from 'hooks/useFilterSort'

import messages from '../../messages'
import type { FilterInputProps } from '../../types'
import Ids, { type SelectorProps } from '../Common/Ids'

import { CardStyle } from './style'

const WarehouseSelector = ({ open, onClose, selected, setSelected }: SelectorProps) => {
  const { query, filterBy, filterByWithoutQuery, sortBy, setQuery, setFilterBy, setSortBy } =
    useFilterSort<WarehouseFilterInput, WarehouseSortInput>(
      { query: '', archived: false },
      { updatedAt: 'DESCENDING' }
    )

  const { nodes, loading, hasMore, loadMore } = useQueryList<
    WarehousesQuery['warehouses']['nodes'][number],
    WarehousesQuery,
    WarehousesQueryVariables
  >(
    warehousesQuery,
    {
      variables: { filterBy, sortBy, page: 1, perPage: 20 },
      fetchPolicy: 'network-only',
    },
    'warehouses'
  )

  return (
    <SlideView isOpen={open} onRequestClose={onClose}>
      <Selector.Many selected={selected.map((id) => ({ id }))}>
        {({ value, dirty, getItemProps }) => (
          <SlideViewLayout>
            <SlideViewNavBar>
              <EntityIcon icon="WAREHOUSE" color="WAREHOUSE" />
              <Filter
                config={WarehouseFilterConfig}
                rootQueryType="Warehouse"
                filterBy={filterByWithoutQuery}
                onChange={setFilterBy}
              />
              <Search query={query} onChange={setQuery} />
              <Sort config={WarehouseSortConfig} sortBy={sortBy} onChange={setSortBy} />
              <CancelButton onClick={onClose} />
              <SaveButton
                disabled={!dirty}
                onClick={() => setSelected(value.map((warehouse) => warehouse.id))}
              />
            </SlideViewNavBar>

            <Content>
              <GridView
                onLoadMore={loadMore}
                hasMore={hasMore}
                isLoading={loading}
                isEmpty={nodes.length === 0}
                emptyMessage={null}
                itemWidth="195px"
              >
                {nodes.map((warehouse) => (
                  <WarehouseCard
                    key={warehouse?.id}
                    warehouse={warehouse}
                    {...getItemProps(warehouse)}
                  />
                ))}
              </GridView>
            </Content>
          </SlideViewLayout>
        )}
      </Selector.Many>
    </SlideView>
  )
}

const WarehouseIds = ({ value, readonly, onChange }: FilterInputProps<string[]>): React.Node => (
  <Ids
    value={value}
    readonly={readonly}
    onChange={onChange}
    title={<FormattedMessage {...messages.warehouses} />}
    selector={WarehouseSelector}
    query={warehousesByIDsQuery}
    getItems={(data) => data?.warehousesByIDs ?? []}
    renderItem={(warehouse) => (
      <BaseCard icon="WAREHOUSE" color="WAREHOUSE" wrapperClassName={CardStyle}>
        <Display height="30px">{warehouse?.name}</Display>
      </BaseCard>
    )}
  />
)

export default WarehouseIds
