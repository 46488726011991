// @flow strict
import * as React from 'react'
import { useCallback, useRef, useState } from 'react'
import DebounceInput from 'react-debounce-input'
import { useIntl } from 'react-intl'

import Display from '@components/Form/Display'
import Icon from '@components/Icon'
import type { PortInput as PortInputType, PortFragment } from '@graphql/server/flow'
import usePort from '@hooks/usePort'
import { getTransportationCodeTypeIcon, portOptionFromPort } from '@modules/shipment/helpers'
import type { PortOption } from '@modules/shipment/types'

import { ClearButtonStyle } from 'components/Form/Inputs/Styles/DefaultStyle/DefaultSelectStyle/DefaultSelect/style'
import { DefaultStyleWrapperStyle } from 'components/Form/Inputs/Styles/DefaultStyle/style'
import SelectInput, {
  type RenderInputProps,
  type RenderOptionProps,
} from 'components/Inputs/SelectInput'
import { getPortType, getTransportationTypeIcon } from 'modules/shipment/helpers'

import { filterItems, itemToString, itemToValue } from './helpers'
import { ArrowDownStyle, OptionStyle, SelectTransportationTypeStyle } from './style'

/**
 * @param  {boolean} eagerSearch? Use to run a search of the portcode value without waiting for user input
 */
type Props = {|
  ...
    | {| value: ?PortInputType, onChange: (PortInputType) => void, eagerSearch: true |}
    | {| value: ?PortFragment, onChange: (PortFragment) => void |},
  readonly: boolean,
  selectInputStyle?: string,
|}

const PortSelectInput = ({
  getInputProps,
  setSearchValue,
  setCanSearch,
  setPorts,
  selectInputStyle,
  inputRef,
  getToggleButtonProps,
  selectedItem,
  isOpen,
  required,
  clearSelection,
  items,
}: RenderInputProps & {
  setSearchValue: (string) => void,
  setCanSearch: (boolean) => void,
  setPorts: (PortOption[]) => void,
  selectInputStyle?: string,
}) => {
  const { transportType, codeType } = selectedItem || {}
  const intl = useIntl()
  const { ref, ...inputProps } = getInputProps({
    spellCheck: false,
    placeholder: intl.formatMessage({
      id: 'components.NavBar.Filter.portPlaceholder',
      defaultMessage: 'Please start typing a port',
    }),
  })

  const transportationCodeIcon = getTransportationCodeTypeIcon({ transportCodeType: codeType })

  return (
    <div
      className={
        selectInputStyle ??
        DefaultStyleWrapperStyle({
          type: 'standard',
          isFocused: isOpen,
          hasError: false,
          disabled: false,
          forceHoverStyle: false,
          width: '200px',
          height: '30px',
        })
      }
    >
      {selectedItem && transportType && (
        <i className={SelectTransportationTypeStyle}>
          {transportationCodeIcon ? (
            <Icon icon={transportationCodeIcon} />
          ) : (
            <Icon icon={getTransportationTypeIcon({ transportType })} />
          )}
        </i>
      )}

      <DebounceInput
        debounceTimeout={500}
        inputRef={ref}
        {...inputProps}
        onChange={(e) => {
          inputProps.onChange(e)
          setSearchValue(e.target.value)
        }}
        onFocus={(e) => {
          inputProps.onFocus(e)
          setCanSearch(true)
        }}
        onBlur={(e) => {
          /**
            When this field is blurred, the downshift component above looks for a match between value and
            and given ports. This causes it to refresh and the value of this field is lost. Therefore, clearPortOptions
            the searchValue.
          */
          inputProps.onBlur(e)
          setSearchValue('')
        }}
      />

      {selectedItem && (!required ?? false) ? (
        <button
          className={ClearButtonStyle}
          // TODO: Fix styling when on table (when a selectInputStyle is passed)
          style={selectInputStyle ?? '' ? { opacity: '1' } : {}}
          type="button"
          onClick={() => {
            clearSelection()
            setPorts([])
            setSearchValue('')
            if (inputRef?.current?.focus) inputRef.current.focus()
          }}
        >
          <Icon icon="CLEAR" />
        </button>
      ) : items.length > 0 ? (
        <button className={ArrowDownStyle(isOpen)} type="button" {...getToggleButtonProps()}>
          <Icon icon="CHEVRON_DOWN" />
        </button>
      ) : (
        <></>
      )}
    </div>
  )
}

const PortSelectOption = ({
  item,
  selected,
  highlighted,
}: {
  ...RenderOptionProps,
  item: PortOption,
}) => {
  const { transportType, codeType } = item

  const transportationCodeIcon = getTransportationCodeTypeIcon({ transportCodeType: codeType })

  return (
    <div className={OptionStyle(highlighted, selected)}>
      {transportType && (
        <i className={SelectTransportationTypeStyle}>
          {transportationCodeIcon ? (
            <Icon icon={transportationCodeIcon} />
          ) : (
            <Icon icon={getTransportationTypeIcon({ transportType })} />
          )}
        </i>
      )}
      <span>{itemToString(item)}</span>
    </div>
  )
}

const PortInput = ({
  value,
  onChange,
  readonly,
  selectInputStyle,
  eagerSearch = false,
}: Props): React.Node => {
  const [searchValue, setSearchValue] = useState<string>('')
  const [canSearch, setCanSearch] = useState<boolean>(eagerSearch ?? false)
  const { ports, setPorts } = usePort({
    searchValue,
    canSearch,
    values: value ? [value] : [],
  })
  const inputRef = useRef()

  // Limit rerenders of the text search input field
  const memoizedRenderSelectInput = useCallback(
    (inputProps) => (
      <PortSelectInput
        {...inputProps}
        setSearchValue={setSearchValue}
        setCanSearch={setCanSearch}
        setPorts={setPorts}
        inputRef={inputRef}
        selectInputStyle={selectInputStyle}
      />
    ),
    [setPorts, selectInputStyle]
  )

  // If no ports yet, and we have a port (with a name), supply the value as an option if it is defined (so as not to confused <SelectInput />)
  const parsedOptions =
    value && value.__typename === 'Port' && !ports.length ? [portOptionFromPort(value)] : ports

  /**
    SelectInput checks by value equality to option mapped to value.
    We need all values (not just those req by mutation) as there is no unique ID.
  */
  const optionValues = parsedOptions.map(itemToValue)

  let parsedValue
  if (value) {
    const portType = getPortType({ port: value })
    parsedValue = optionValues.find(
      (optionValue) => portType && value[portType] === optionValue[portType]
    )
  }

  if (readonly) {
    return (
      <Display height="30px" width="200px">
        {parsedValue ? itemToString(parsedValue) : ''}
      </Display>
    )
  }

  return (
    <SelectInput
      value={parsedValue}
      onChange={onChange}
      items={parsedOptions}
      filterItems={filterItems}
      itemToString={itemToString}
      itemToValue={itemToValue}
      renderOption={PortSelectOption}
      renderInput={memoizedRenderSelectInput}
      inputRef={inputRef}
    />
  )
}

export default PortInput
