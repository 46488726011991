import { Interpolation } from 'styled-components'

import Icon from '@components/Icon'

import { BlackoutWrapperStyle } from './styles'

interface Props {
  width?: string
  height?: string
  styles?: Interpolation<React.CSSProperties>
}

const Blackout = ({ width = '100%', height = '100%', styles, ...rest }: Props) => (
  <BlackoutWrapperStyle width={width} height={height} styles={styles} {...rest}>
    <Icon icon="DISABLED" />
  </BlackoutWrapperStyle>
)

export default Blackout
