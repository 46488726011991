// @flow

import { useMutation } from '@apollo/client'
import { cloneDeep } from 'lodash'
import * as React from 'react'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import type { UploadingFile } from 'types/file'

import Display from '@components/Form/Display'
import fileDeleteMutation from '@graphql/client/document/mutation.fileDelete.graphql'
import fileDeleteManyMutation from '@graphql/client/document/mutation.fileDeleteMany.graphql'
import fileUploadMutation from '@graphql/client/document/mutation.fileUpload.graphql'
import type {
  File,
  MessageGroup,
  Mutation,
  MutationFileDeleteArgs,
  MutationFileDeleteManyArgs,
  MutationFileUploadArgs,
  Partner,
  User,
} from '@graphql/server/flow'

import messages from 'components/Form/DocumentsUpload/messages'
import { DocumentsUploadWrapperStyle } from 'components/Form/DocumentsUpload/style'
import { SlideViewLayout, SlideViewNavBar } from 'components/Layout'
import { EntityIcon } from 'components/NavBar'
import SlideView from 'components/SlideView'
import Chat from 'modules/chat'
import emitter from 'utils/emitter'
import { uuid } from 'utils/id'
import logger from 'utils/logger'

import DocumentTypeAreaLight from '../DocumentTypeArea/index.light'

type TempMessageGroup = {
  ...MessageGroup,
  files: File | UploadingFile[],
}

type Props = {
  entity: 'Order' | 'OrderItem' | 'Shipment' | 'ProductProvider',
  entityId: string,
  messageGroups: TempMessageGroup[],
  setMessageGroups: Function,
  relatedPartners: Partner[],
  mentionUsers: User[],
  hideMessageBubble?: boolean,
}

const DeliveryBoxSection = ({
  entity,
  entityId,
  messageGroups,
  setMessageGroups,
  relatedPartners,
  mentionUsers,
  hideMessageBubble,
}: Props): React.Element<'div'> => {
  const [groupId, setGroupId] = useState<string | null>(null)

  const [deleteFilesMutation] = useMutation<Mutation, MutationFileDeleteManyArgs>(
    fileDeleteManyMutation
  )
  const [deleteFileMutation] = useMutation<Mutation, MutationFileDeleteArgs>(fileDeleteMutation)

  const [upload] = useMutation<Mutation, MutationFileUploadArgs>(fileUploadMutation)

  const handleUpload = (
    event: SyntheticInputEvent<HTMLInputElement> | File[],
    messageGroupId: string
  ) => {
    let newFiles = []

    if (Array.isArray(event)) {
      newFiles = event
    } else {
      event.preventDefault()
      newFiles = Array.from(event.target.files)
    }

    const updatedMessageGroup = cloneDeep(
      messageGroups.find((_messageGroup) => _messageGroup.id === messageGroupId)
    )

    if (!updatedMessageGroup) {
      return
    }

    const newFileObjects = newFiles.map(({ name }) => ({
      name,
      id: uuid(),
      path: '',
      memo: '',
      tags: [],
      uploading: true,
      progress: 0,
      isNew: true,
      createdAt: '',
      __typename: 'UploadFile',
    }))

    updatedMessageGroup.files = [...updatedMessageGroup.files, ...newFileObjects]

    setMessageGroups((oldMessageGroups) =>
      oldMessageGroups.map((oldMessageGroup) => {
        if (oldMessageGroup.id === messageGroupId) {
          return { ...updatedMessageGroup }
        }

        return oldMessageGroup
      })
    )

    Promise.all<any>(
      newFiles.map((newFile) =>
        upload({
          variables: {
            file: newFile,
            input: {
              type: 'File',
              entity: {
                [`${entity.toLowerCase()}Id`]: entityId,
              },
              messageGroupId,
            },
          },
          context: ({
            fetchOptions: {
              onProgress: (progressEvent: ProgressEvent) => {
                const { lengthComputable, loaded, total } = progressEvent

                if (lengthComputable) {
                  setMessageGroups((oldMessageGroups) =>
                    oldMessageGroups.map((oldMessageGroup) => {
                      if (oldMessageGroup.id !== messageGroupId) {
                        return oldMessageGroup
                      }

                      const newMessageGroup = { ...oldMessageGroup }
                      newMessageGroup.files = newMessageGroup.files.map((file) => ({
                        ...file,
                        progress:
                          newFile.id && file.id === newFile.id
                            ? Math.round((loaded / total) * 100)
                            : file.progress,
                      }))

                      return { ...newMessageGroup }
                    })
                  )
                }
              },
            },
          }: any),
        })
      )
    )
      .then((uploadResults) => {
        setMessageGroups((oldMessageGroups) =>
          oldMessageGroups.map((oldMessageGroup) => {
            if (oldMessageGroup.id !== messageGroupId) {
              return oldMessageGroup
            }

            const newMessageGroup = { ...oldMessageGroup }
            newMessageGroup.files = [
              ...newMessageGroup.files.filter((file) => !file.isNew),
              ...uploadResults.map((result) => result.data.fileUpload),
            ]

            return { ...newMessageGroup }
          })
        )
      })
      .catch((error) => {
        logger.error(error)

        // remove new files since an error occurred
        setMessageGroups((oldMessageGroups) =>
          oldMessageGroups.map((oldMessageGroup) => {
            if (oldMessageGroup.id !== messageGroupId) {
              return oldMessageGroup
            }

            const newMessageGroup = { ...oldMessageGroup }
            newMessageGroup.files = newMessageGroup.files.filter((file) => !file.isNew)

            return { ...newMessageGroup }
          })
        )
      })
  }

  return (
    <div className={DocumentsUploadWrapperStyle}>
      {messageGroups.length === 0 && (
        <Display align="center">
          <FormattedMessage {...messages.noMessageGroups} />
        </Display>
      )}
      {messageGroups.map((messageGroup) => {
        return (
          <DocumentTypeAreaLight
            key={messageGroup.id}
            groupId={messageGroup.id}
            entityType={entity}
            label={messageGroup.organizations
              .map((organization) => organization.name || '')
              .join(', ')}
            // $FlowFixMe: violations not found in the custom type
            files={messageGroup.files}
            unreadMessageCount={messageGroup.unreadMessageCount}
            hideMessageBubble={hideMessageBubble}
            onUpload={(evt) => handleUpload(evt, messageGroup.id)}
            canDownload
            canDelete
            isMultiSelect={false}
            selectedFiles={{}}
            onDocumentClick={() => {}}
            onBubbleClick={(clickedGroupId) => setGroupId(clickedGroupId)}
            onDeleteClick={(fileId) => {
              deleteFileMutation({
                variables: {
                  id: fileId,
                },
              })
                .then(() => {
                  setMessageGroups((oldMessageGroups) =>
                    oldMessageGroups.map((oldMessageGroup) => {
                      if (oldMessageGroup.id !== messageGroup.id) {
                        return oldMessageGroup
                      }

                      return {
                        ...oldMessageGroup,
                        files: oldMessageGroup.files.filter((file) => file.id !== fileId),
                      }
                    })
                  )
                })
                .catch((error) => {
                  logger.error(error)
                })
            }}
            onDeleteAllClick={() => {
              const fileIdsToDelete = messageGroup.files.map((file) => file.id).filter(Boolean)

              deleteFilesMutation({
                variables: {
                  ids: fileIdsToDelete,
                },
              })
                .then(() => {
                  setMessageGroups((oldMessageGroups) =>
                    oldMessageGroups.map((oldMessageGroup) => {
                      if (oldMessageGroup.id !== messageGroup.id) {
                        return oldMessageGroup
                      }

                      return {
                        ...oldMessageGroup,
                        files: [],
                      }
                    })
                  )
                })
                .catch((error) => {
                  logger.error(error)
                })
            }}
          />
        )
      })}
      <SlideView
        isOpen={!!groupId}
        onRequestClose={() => {
          setGroupId(null)
          emitter.emit('MESSAGES_CLOSE')
        }}
      >
        <SlideViewLayout
          navBar={
            <SlideViewNavBar>
              <EntityIcon icon="MESSAGES" color="LOGS" />
            </SlideViewNavBar>
          }
        >
          {entity && !!groupId && (
            <Chat
              entityId={entityId}
              entityType={entity}
              defaultSelectedGroup={groupId}
              relatedPartners={relatedPartners}
              mentionUsers={mentionUsers}
              onListItemIconClick={(id) => {
                setGroupId(null)
                emitter.emit('CHAT_LIST_ITEM_CLICK', id, false)
              }}
            />
          )}
        </SlideViewLayout>
      </SlideView>
    </div>
  )
}

export default DeliveryBoxSection
