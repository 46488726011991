import { init } from '@sentry/browser'

import { isAppInProduction, isEnableErrorReport } from './utils/env'

const errorReport = () => {
  if (isAppInProduction && isEnableErrorReport) {
    init({
      dsn: process.env.ZENPORT_SENTRY_URL || '',
      whitelistUrls: ['https://my.zenport.io', 'https://staging.zenport.io'],
      ignoreErrors: [/ResizeObserver loop completed with undelivered notifications/], // Ignore errors like suggested in this article: https://sentry.io/answers/react-resizeobserver-loop-completed-with-undelivered-notifications/
    })
  }
}

export default errorReport
