import styled from 'styled-components'

import { UploadStatus } from '@graphql/server/typescript'

import { borderRadiuses, colors, fontSizes } from '@styles/common'

export const StatusWrapperStyle = styled.div<{ status: UploadStatus }>`
  position: absolute;
  right: 30px;
  top: 8px;
  color: initial;
  text-align: center;
  font-size: ${fontSizes.TINY};
  border: 2px solid black;
  border-radius: ${borderRadiuses.MAIN_INSIDE};
  padding: 3px;
  background-color: ${({ status }: { status: UploadStatus }) => {
    switch (status) {
      case UploadStatus.Pending:
        return colors.GRAY_LIGHT
      case UploadStatus.Blocked:
        return colors.ORANGE_DARK
      case UploadStatus.Uploaded:
        return colors.GREEN_LIGHT
      case UploadStatus.Failed:
        return colors.RED
    }
  }};
`

export default StatusWrapperStyle
