// @flow
import * as React from 'react'
import { type IntlShape, injectIntl } from 'react-intl'

import Display from '@components/Form/Display'

import { type InputProps, defaultInputProps } from 'components/Form/Inputs/type'
import FormattedNumber from 'components/FormattedNumber'
import BaseNumberInput from 'components/Inputs/NumberInput'

type OptionalProps = {
  nullable: boolean,
  readOnlySuffix: ?(string | React.Node),
}

type Props = OptionalProps &
  InputProps & {
    intl: IntlShape,
    register?: Object,
  }

export type NumberInputProps = Props

const defaultProps = {
  ...defaultInputProps,
  nullable: false,
  readOnlySuffix: null,
}

export const defaultNumberInputProps = defaultProps

class NumberInput extends React.Component<Props> {
  static defaultProps = defaultProps

  render() {
    const {
      intl,
      value,
      align,
      name,
      readOnly,
      readOnlyWidth,
      readOnlyHeight,
      readOnlySuffix,
      register = {},
      placeholder,
      nullable,
      inputRef,
      ...rest
    } = this.props

    return readOnly ? (
      <Display align={align} width={readOnlyWidth} height={readOnlyHeight}>
        <FormattedNumber value={value} />
        {readOnlySuffix}
      </Display>
    ) : (
      <BaseNumberInput
        ref={inputRef}
        value={value === null ? '' : value}
        style={{ textAlign: align }}
        placeholder={placeholder}
        {...rest}
        {...register}
        isReactHookForm={!!Object.keys(register).length}
      />
    )
  }
}

export default (injectIntl(NumberInput): any)
