// @flow
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import Display from '@components/Form/Display'
import ordersByIDsQuery from '@graphql/client/order/query.ordersByIDs.graphql'
import ordersQuery from '@graphql/client/order/query.ordersCard.graphql'
import type { OrderFilterInput, OrderSortInput } from '@graphql/server/flow'
import useQueryList from '@hooks/useQueryList'

import { CancelButton, SaveButton, SelectAllButton } from 'components/Buttons'
import { OrderCard } from 'components/Cards'
import BaseCard from 'components/Cards/BaseCard'
import GridView from 'components/GridView'
import { Content, SlideViewLayout, SlideViewNavBar } from 'components/Layout'
import {
  BulkHeaderFilter,
  EntityIcon,
  Filter,
  OrderFilterConfig,
  OrderSortConfig,
  Search,
  Sort,
} from 'components/NavBar'
import Selector from 'components/Selector'
import SlideView from 'components/SlideView'
import useFilterSort from 'hooks/useFilterSort'

import messages from '../../messages'
import type { FilterInputProps } from '../../types'
import Ids, { type SelectorProps } from '../Common/Ids'

import { CardStyle } from './style'

const OrderSelector = ({ open, onClose, selected, setSelected }: SelectorProps) => {
  const { query, filterBy, filterByWithoutQuery, sortBy, setQuery, setFilterBy, setSortBy } =
    useFilterSort<OrderFilterInput, OrderSortInput>({ query: '' }, { updatedAt: 'DESCENDING' })

  const { nodes, loading, hasMore, loadMore } = useQueryList(
    ordersQuery,
    {
      variables: { filterBy, sortBy, page: 1, perPage: 20 },
      fetchPolicy: 'network-only',
    },
    'orders'
  )

  return (
    <SlideView isOpen={open} onRequestClose={onClose}>
      <Selector.Many items={nodes} selected={selected.map((id) => ({ id }))}>
        {({ value, dirty, getItemProps, isAllSelected, onSelectAll }) => (
          <SlideViewLayout>
            <SlideViewNavBar>
              <EntityIcon icon="ORDER" color="ORDER" />
              <Filter
                config={OrderFilterConfig.filter((c) => c.field !== 'ids')}
                rootQueryType="Order"
                filterBy={filterByWithoutQuery}
                onChange={setFilterBy}
              />
              <Search query={query} onChange={setQuery} />

              <BulkHeaderFilter
                filterBy={filterByWithoutQuery}
                setFilterBy={setFilterBy}
                type="ORDER"
              />
              <Sort sortBy={sortBy} onChange={setSortBy} config={OrderSortConfig} />
              <CancelButton onClick={onClose} />
              <SaveButton
                disabled={!dirty}
                onClick={() => {
                  const newSelected = value.map((order) => order.id)
                  setSelected([...new Set(newSelected)])
                }}
              />
              <SelectAllButton
                right={15}
                disabled={loading}
                isAllSelected={isAllSelected}
                onClick={() => {
                  onSelectAll(nodes)
                }}
                labelSuffix={value.length ? ` (${value.length.toString()})` : ''}
              />
            </SlideViewNavBar>

            <Content id="antd-modal">
              <GridView
                isLoading={loading}
                hasMore={hasMore}
                onLoadMore={loadMore}
                isEmpty={nodes.length === 0}
                emptyMessage={null}
                itemWidth="195px"
              >
                {nodes.map((order) => (
                  <OrderCard key={order?.id} order={order} {...getItemProps(order)} />
                ))}
              </GridView>
            </Content>
          </SlideViewLayout>
        )}
      </Selector.Many>
    </SlideView>
  )
}

const OrderIds = ({ value, readonly, onChange }: FilterInputProps<string[]>): React.Node => {
  return (
    <Ids
      value={value}
      readonly={readonly}
      onChange={onChange}
      title={<FormattedMessage {...messages.orders} />}
      selector={OrderSelector}
      query={ordersByIDsQuery}
      getItems={(data) => data?.ordersByIDs ?? []}
      renderItem={(order) => (
        <BaseCard icon="ORDER" color="ORDER" wrapperClassName={CardStyle}>
          <Display height="30px">{order?.poNo}</Display>
        </BaseCard>
      )}
    />
  )
}

export default OrderIds
