import { GrayCard } from 'components/Cards'

import Blackout from '../Blackout'

import { DisplayWrapperStyle } from './styles'

interface Props {
  align?: 'left' | 'right' | 'center'
  width?: string
  height?: string
  color?: string
  fontSize?: string
  blackout?: boolean
  grayout?: boolean
  lines?: number
  children: React.ReactNode
}

const Display = ({
  align = 'left',
  width = '100%',
  height = '20px',
  color = 'BLACK',
  fontSize = 'MAIN',
  lines = 0,
  blackout,
  grayout = false,
  children,
  ...rest
}: Props) =>
  grayout ? (
    <GrayCard width={width} height={height} />
  ) : blackout ? (
    <Blackout width={width} height={height} />
  ) : (
    <DisplayWrapperStyle
      align={align}
      width={width}
      height={height}
      color={color}
      fontSize={fontSize}
      lines={lines}
      {...rest}
    >
      {children}
    </DisplayWrapperStyle>
  )

export default Display
