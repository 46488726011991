// @flow
import * as React from 'react'
import InfiniteScroll from 'react-infinite-scroller'

import Display from '@components/Form/Display'

import LoadingIcon, { type Props as LoadingIconProps } from 'components/LoadingIcon'

import { EmptyGridViewStyle, GridViewWrapperStyle } from './style'

type Props = {
  itemWidth: string,
  children: React$Node,
  gap?: string,
  isReverse?: boolean,
  columnGap?: string,
  rowGap?: string,
  padding?: string,
  loader?: React$Element<(LoadingIconProps) => React$Node>,
  onLoadMore?: Function,
  hasMore?: boolean,
  isLoading?: boolean,
  isEmpty?: boolean,
  emptyMessage?: any,
  threshold?: number,
  scrollParentRef?: React.ElementRef<any>,
}

function GridView(props: Props): React.Node {
  const {
    onLoadMore,
    hasMore,
    isLoading,
    isEmpty,
    emptyMessage,
    itemWidth,
    isReverse = false,
    padding = '50px 20px',
    gap,
    columnGap = '20px',
    rowGap = '30px',
    children,
    loader = <LoadingIcon key="loading" />,
    scrollParentRef,
    threshold = 500,
  } = props

  if (isLoading) {
    return <LoadingIcon />
  }

  if (!hasMore && isEmpty) {
    return (
      <div className={EmptyGridViewStyle}>
        <Display align="center">{emptyMessage}</Display>
      </div>
    )
  }

  return (
    <InfiniteScroll
      className={`${GridViewWrapperStyle({
        itemWidth,
        columnGap: gap || columnGap,
        rowGap: gap || rowGap,
        padding,
      })} InfiniteScroll`}
      getScrollParent={scrollParentRef ? () => scrollParentRef.current : undefined}
      loadMore={onLoadMore}
      hasMore={hasMore}
      loader={loader}
      isReverse={isReverse}
      threshold={threshold}
      useWindow={false}
    >
      {children}
    </InfiniteScroll>
  )
}

export default GridView
