// @flow
import * as React from 'react'

import Display from '@components/Form/Display'
import type { File } from '@graphql/server/flow'
import FALLBACK_IMAGE from '@public/media/logo_fallback.png'
import { isForbidden } from '@utils/data'
import { isSubPath, isNext } from '@utils/env'

import type { Payload, $Pick } from 'types'
import { getByPathWithDefault } from 'utils/fp'

type Props = {
  className: string,
  file: ?Payload<$Pick<File, { __typename: *, name: *, pathMedium: * }>>,
  path?: string,
  height?: string,
}

const defaultProps = {
  path: 'pathMedium',
  height: '75px',
}

export default function ProductImage({ className, file, path, height }: Props): React.Node {
  const alt = getByPathWithDefault('product image', 'name', file)
  const src = getByPathWithDefault(
    isNext ? `${isSubPath ? '/new' : ''}/media/logo_fallback.png` : FALLBACK_IMAGE,
    path || '',
    file
  )
  if (isForbidden(file)) {
    return <Display className={className} blackout height={height} />
  }
  return <img className={className} src={src} alt={alt} />
}

ProductImage.defaultProps = defaultProps
