// @flow strict
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import Display from '@components/Form/Display'
import shipmentsByIDsQuery from '@graphql/client/shipment/query.shipmentsByIDs.graphql'
import shipmentsCardQuery from '@graphql/client/shipment/query.shipmentsCard.graphql'
import type {
  ShipmentsCardQuery,
  ShipmentsCardQueryVariables,
  ShipmentFilterInput,
  ShipmentSortInput,
} from '@graphql/server/flow'
import useQueryList from '@hooks/useQueryList'

import { CancelButton, SaveButton, SelectAllButton } from 'components/Buttons'
import { ShipmentCard } from 'components/Cards'
import BaseCard from 'components/Cards/BaseCard'
import GridView from 'components/GridView'
import { Content, SlideViewLayout, SlideViewNavBar } from 'components/Layout'
import {
  BulkHeaderFilter,
  EntityIcon,
  Filter,
  Search,
  ShipmentFilterConfig,
  ShipmentSortConfig,
  Sort,
} from 'components/NavBar'
import Selector from 'components/Selector'
import SlideView from 'components/SlideView'
import useFilterSort from 'hooks/useFilterSort'

import messages from '../../messages'
import type { FilterInputProps } from '../../types'
import Ids, { type SelectorProps } from '../Common/Ids'

import { CardStyle } from './style'

const ShipmentSelector = ({ open, onClose, selected, setSelected }: SelectorProps) => {
  const { query, filterBy, filterByWithoutQuery, sortBy, setQuery, setFilterBy, setSortBy } =
    useFilterSort<ShipmentFilterInput, ShipmentSortInput>(
      { query: '' },
      { updatedAt: 'DESCENDING' }
    )

  const { nodes, loading, hasMore, loadMore } = useQueryList<
    ShipmentsCardQuery['shipments']['nodes'],
    ShipmentsCardQuery,
    ShipmentsCardQueryVariables
  >(
    shipmentsCardQuery,
    {
      variables: { filterBy, sortBy, page: 1, perPage: 20 },
      fetchPolicy: 'network-only',
    },
    'shipments'
  )

  return (
    <SlideView isOpen={open} onRequestClose={onClose}>
      <Selector.Many items={nodes} selected={selected.map((id) => ({ id }))}>
        {({ value, dirty, getItemProps, isAllSelected, onSelectAll }) => (
          <SlideViewLayout>
            <SlideViewNavBar>
              <EntityIcon icon="SHIPMENT" color="SHIPMENT" />
              <Filter
                config={ShipmentFilterConfig.filter((c) => c.field !== 'ids')}
                rootQueryType="Shipment"
                filterBy={filterByWithoutQuery}
                onChange={setFilterBy}
              />
              <Search query={query} onChange={setQuery} />
              <BulkHeaderFilter
                filterBy={filterByWithoutQuery}
                setFilterBy={setFilterBy}
                type="SHIPMENT"
              />
              <Sort sortBy={sortBy} onChange={setSortBy} config={ShipmentSortConfig} />
              <CancelButton onClick={onClose} />
              <SaveButton
                disabled={!dirty}
                onClick={() => {
                  const newSelected = value.map((shipment) => shipment.id)
                  setSelected([...new Set(newSelected)])
                }}
              />
              <SelectAllButton
                right={15}
                isAllSelected={isAllSelected}
                onClick={() => {
                  onSelectAll(nodes)
                }}
                labelSuffix={value.length ? ` (${value.length.toString()})` : ''}
              />
            </SlideViewNavBar>

            <Content>
              <GridView
                onLoadMore={loadMore}
                hasMore={hasMore}
                isLoading={loading}
                isEmpty={nodes.length === 0}
                emptyMessage={null}
                itemWidth="860px"
              >
                {nodes.map((shipment) => (
                  <ShipmentCard
                    key={shipment?.id}
                    shipment={shipment}
                    {...getItemProps(shipment)}
                  />
                ))}
              </GridView>
            </Content>
          </SlideViewLayout>
        )}
      </Selector.Many>
    </SlideView>
  )
}

const ShipmentIds = ({ value, readonly, onChange }: FilterInputProps<string[]>): React.Node => (
  <Ids
    value={value}
    readonly={readonly}
    onChange={onChange}
    title={<FormattedMessage {...messages.shipments} />}
    selector={ShipmentSelector}
    query={shipmentsByIDsQuery}
    getItems={(data) => data?.shipmentsByIDs ?? []}
    renderItem={(shipment) => (
      <BaseCard icon="SHIPMENT" color="SHIPMENT" wrapperClassName={CardStyle}>
        <Display height="30px">{shipment?.no}</Display>
      </BaseCard>
    )}
  />
)

export default ShipmentIds
