// @flow
import * as React from 'react'
import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import Display from '@components/Form/Display'
import type { File } from '@graphql/server/flow'

import { FieldItem, Label } from 'components/Form'
import {
  computeIcon,
  getFileExtension,
  getFileName,
  getMimeType,
} from 'components/Form/DocumentsUpload/helpers'
import FormattedDate from 'components/FormattedDate'
import GridColumn from 'components/GridColumn'
import Icon from 'components/Icon'
import LoadingIcon from 'components/LoadingIcon'
import { Tooltip } from 'components/Tooltip'

import type { LocalFile, UploadingFile } from '../../../types/file'
import BaseCard from '../BaseCard'

import {
  ButtonLightWrapperStyle,
  CreatedAtStyle,
  DocumentLightCardWrapperStyle,
  DownloadButtonStyle,
  FileExtensionIconStyle,
  FileNameStyle,
  FileNameWrapperStyle,
  FilePreviewStyle,
  OwnedByStyle,
  ThumbnailPreviewStyle,
} from './style'

type Props = {|
  file: File | LocalFile | UploadingFile,
  error?: string,
  actions?: React$Node[],
  hideParentInfo?: boolean,
  downloadable?: boolean,
  navigable?: boolean,
  selected?: boolean,
  onChange?: (field: string, value: mixed) => void,
  onClick?: Function,
  onSelect?: Function,
|}

const getBlobFromUrl = (myImageUrl: string) =>
  new Promise<Blob>((resolve, reject) => {
    const request = new XMLHttpRequest()
    request.open('GET', myImageUrl, true)
    request.responseType = 'blob'
    request.onload = () => {
      resolve(request.response)
    }
    request.onerror = reject
    request.send()
  })

const DocumentLightCard = ({
  file,
  error,
  hideParentInfo,
  actions,
  downloadable,
  navigable,
  onSelect,
  onChange,
  onClick,
  ...rest
}: Props): React.Node => {
  const [imgData, setImgData] = useState<?string>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const name = file.name ?? ''
  const fileExtension = getFileExtension(name)
  const mimetype =
    file.__typename === 'File' || file.__typename === 'LocalFile' ? getMimeType(file) : undefined
  const fileName = getFileName(name)
  const fileIcon = computeIcon(fileExtension)
  const createdAt = file.createdAt ?? ''

  useEffect(() => {
    if (file.preview)
      (async () => {
        // $FlowFixMe: assert defined
        const blob = await getBlobFromUrl(file.preview)
        if (blob) {
          setImgData(URL.createObjectURL(blob))
          setIsLoading(false)
        } else {
          const fileReader = new FileReader()
          // $FlowFixMe: Doesn't understand progress event
          fileReader.onload = ({ target: { result } }) => {
            if (!result) return
            setImgData(result)
            setIsLoading(false)
          }
          if (blob) {
            fileReader.readAsArrayBuffer(blob)
          } else if (file.__typename === 'LocalFile') {
            fileReader.readAsDataURL(file.file)
          }
        }
      })()
  }, [file, mimetype])

  const img = (
    <img
      alt="message_thumbnail_preview"
      className={`${ThumbnailPreviewStyle} asdasd`}
      src={imgData}
    />
  )

  return (
    <BaseCard
      actions={actions}
      error={error}
      showActionsOnHover
      color="DOCUMENT"
      onClick={onClick}
      onSelect={() => {
        if (onSelect) {
          onSelect(file)
        }
      }}
      {...rest}
    >
      <div className={DocumentLightCardWrapperStyle}>
        {file.preview ? (
          <div className={`${FilePreviewStyle} FilePreviewStyle`}>
            {isLoading && <LoadingIcon />}
            {imgData && !isLoading && Array(2).fill(img)}
          </div>
        ) : (
          <div className={FileExtensionIconStyle(fileIcon.color, true)}>
            <Icon {...fileIcon} />
          </div>
        )}
        <GridColumn gap="5px">
          <Tooltip message={`${fileName}.${fileExtension}`}>
            <div className={FileNameWrapperStyle(true)}>
              <div className={FileNameStyle(true)}>
                {fileName}.{fileExtension}
              </div>
            </div>
          </Tooltip>
          <FieldItem
            label={
              <Tooltip message={file.ownedBy?.id ? file.ownedBy.name : ''}>
                <span>
                  <Label className={OwnedByStyle}>
                    {file.ownedBy?.id ? file.ownedBy.name : ''}
                  </Label>
                </span>
              </Tooltip>
            }
            input={
              <Display className={CreatedAtStyle(true)}>
                <FormattedDate value={createdAt} mode="datetime" />
              </Display>
            }
          />
        </GridColumn>

        <div className={ButtonLightWrapperStyle}>
          {/* {downloadable && ( */}
          <Tooltip
            message={
              <FormattedMessage
                id="components.documentInput.cantDownload"
                defaultMessage="You do not have the rights to download this document"
              />
            }
            enabled={false}
          >
            <div className={DownloadButtonStyle({ disabled: false })}>
              <Icon
                icon="DOWNLOAD"
                onClick={(e) => {
                  e.stopPropagation()
                  if (file.__typename === 'LocalFile') {
                    const path = URL.createObjectURL(file.file)
                    window.open(path ?? '', '_blank')
                  } else {
                    window.open(file?.path ?? '', '_blank')
                  }
                }}
              />
            </div>
          </Tooltip>
          {/* )} */}
        </div>
      </div>
    </BaseCard>
  )
}

export default DocumentLightCard
