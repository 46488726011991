import styled, { css } from 'styled-components'

import { borderRadiuses, colors, fontSizesWithHeights, presets } from '@styles/common'

type DisplayWrapperType = {
  align: 'left' | 'right' | 'center'
  width: string
  height: string
  color: string
  fontSize: string
  lines: number
}

const webkitLines = (numLines: number, height = '20px') => {
  if (!numLines) {
    return css`
      height: ${height};
    `
  }

  return css`
    -webkit-line-clamp: ${numLines};
    height: ${numLines * 20}px;
  `
}

export const DisplayWrapperStyle = styled.div<DisplayWrapperType>`
  ${({ align, width, height, color, fontSize, lines }) => css`
    ${fontSizesWithHeights[fontSize]};
    height: ${height};
    color: ${colors[color]};
    ${lines ? presets.MULTI_LINE_ELLIPSIS : presets.ELLIPSIS};
    ${webkitLines(lines, height)}
    text-align: ${align};
    width: ${width};
    max-width: ${width};
    line-height: ${height};
  `}

  font-weight: bold;
  border-radius: ${borderRadiuses.MAIN};
  min-width: 0;
  flex: 1;
  padding: 0 5px;
`

export default DisplayWrapperStyle
