import styled, { Interpolation } from 'styled-components'

import { borderRadiuses, fontSizes } from '@styles/common'

type BlackoutWrapperType = {
  width: string
  height: string
  styles: Interpolation<React.CSSProperties>
}

export const BlackoutWrapperStyle = styled.div<BlackoutWrapperType>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${fontSizes.MAIN};
  color: rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: ${borderRadiuses.MAIN};
  min-width: 0;
  width: ${({ width }) => width};
  max-width: ${({ width }) => width};
  flex: 1;
  height: ${({ height }) => height};
  ${({ styles }) => styles};
`

export default BlackoutWrapperStyle
