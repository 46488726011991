// @flow strict
import { getDatabase, ref, onValue } from 'firebase/database'
import * as React from 'react'

import firebaseApp from '../../firebase'
import { unregister } from '../../serviceWorker'

type Props = {
  revision: string,
  revisionKey: string,
}

const DeployHandler = ({ revision, revisionKey }: Props): null => {
  React.useEffect(() => {
    const database = getDatabase(firebaseApp) // Get database instance
    const docRef = ref(database, `/${revisionKey}`) // Get reference to path

    onValue(docRef, (snapshot) => {
      if (!snapshot.exists()) {
        return
      }
      const currentRevision = snapshot.val()

      if (revision !== currentRevision) {
        unregister()
        window.swExpired = true
      }
    })
  }, [revision, revisionKey])

  return null
}

export default DeployHandler
