import React from 'react'

import { UploadStatus } from '@graphql/server/typescript'

import { Tooltip } from '@components/Tooltip'

import { StatusWrapperStyle } from './style'

const toolTipMessages = {
  [UploadStatus.Blocked]: 'Please check authentication',
  [UploadStatus.Uploaded]: 'Sent to FUJIFILM IWPro',
  [UploadStatus.Pending]: 'Pending for FUJIFILM IWPro',
  [UploadStatus.Failed]: 'Failed',
}

interface Props {
  status: UploadStatus
}

// TODO: Refactor so this component is not hardcoded to one client, FUJIFILM
export default function Status({ status }: Props) {
  return (
    <Tooltip message={toolTipMessages[status]}>
      <StatusWrapperStyle status={status}>
        FUJIFILM
        <br />
        IWPRO
      </StatusWrapperStyle>
    </Tooltip>
  )
}
