// @flow strict
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import Display from '@components/Form/Display'
import organizationsByIDsQuery from '@graphql/client/common/query.organizationsByIDs.graphql'
import type { Organization, OrganizationType } from '@graphql/server/flow'

import BaseCard from 'components/Cards/BaseCard'
import SelectRelationPartners from 'components/SelectRelationPartners'
import SlideView from 'components/SlideView'

import messages from '../../messages'
import type { FilterInputProps } from '../../types'
import Ids from '../Common/Ids'

import { CardStyle } from './style'

const OrganizationIdsImpl =
  (organizationType: ?OrganizationType, title: React.Node) =>
  ({ value, readonly, onChange }: FilterInputProps<string[]>) =>
    (
      <Ids
        value={value}
        readonly={readonly}
        onChange={onChange}
        title={title}
        selector={({ open, onClose, selected, setSelected }) => (
          <SlideView isOpen={open} onRequestClose={onClose}>
            <SelectRelationPartners
              partnerTypes={organizationType ? [organizationType] : []}
              selected={selected.map(
                (id) => ({ __typename: 'Organization', id }: $Shape<Organization>)
              )}
              onCancel={onClose}
              onSelect={(_selected) =>
                setSelected(
                  _selected.map((o) =>
                    // If from original saved filter, will be ID, if selected by modal will be $Shape<Organization>
                    o.__typename === 'Organization' ? o.id : o
                  )
                )
              }
            />
          </SlideView>
        )}
        query={organizationsByIDsQuery}
        getItems={(data) => data?.organizationsByIDs ?? []}
        renderItem={(organization) => (
          <BaseCard
            icon="PARTNER"
            color="PARTNER"
            wrapperClassName={CardStyle}
            key={organization?.id}
          >
            <Display height="30px">{organization?.partner?.name || organization?.name}</Display>
          </BaseCard>
        )}
      />
    )

// these variables are imported in
// src/components/NavBar/components/Filter/index.js

export const ImporterIds: (FilterInputProps<string[]>) => React.Node = OrganizationIdsImpl(
  'Importer',
  <FormattedMessage {...messages.importers} />
)
export const ExporterIds: (FilterInputProps<string[]>) => React.Node = OrganizationIdsImpl(
  'Exporter',
  <FormattedMessage {...messages.exporters} />
)
export const SupplierIds: (FilterInputProps<string[]>) => React.Node = OrganizationIdsImpl(
  'Supplier',
  <FormattedMessage {...messages.suppliers} />
)
export const ForwarderIds: (FilterInputProps<string[]>) => React.Node = OrganizationIdsImpl(
  'Forwarder',
  <FormattedMessage {...messages.forwarders} />
)
export const WarehouserIds: (FilterInputProps<string[]>) => React.Node = OrganizationIdsImpl(
  'Warehouser',
  <FormattedMessage {...messages.warehousers} />
)

export default (OrganizationIdsImpl(null, <FormattedMessage {...messages.organizations} />): (
  FilterInputProps<string[]>
) => React.Node)
