// @flow
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { BooleanValue } from 'react-values'

import Display from '@components/Form/Display'
import type { Batch, NewPricePayload } from '@graphql/server/flow'
import { ORDER_ITEMS_GET_PRICE } from '@modules/permission/constants/orderItem'
import { isForbidden } from '@utils/data'
import { getCurrentDate } from '@utils/shipment'

import RemoveDialog from 'components/Dialog/RemoveDialog'
import { DateInputFactory, Label, NumberInputFactory, TextInputFactory } from 'components/Form'
import FormattedDate from 'components/FormattedDate'
import FormattedNumber from 'components/FormattedNumber'
import Icon from 'components/Icon'
import RelateEntity from 'components/RelateEntity'
import Tag from 'components/Tag'
import withForbiddenCard from 'hoc/withForbiddenCard'
import usePartnerPermission from 'hooks/usePartnerPermission'
import usePermission from 'hooks/usePermission'
import {
  DELIVERED_QUANTITY,
  INITIAL_QUANTITY,
  POST_SHIPPED_QUANTITY,
  PRE_SHIPPED_QUANTITY,
  PRODUCED_QUANTITY,
  SHIPPED_QUANTITY,
} from 'modules/batch/constants'
import messages from 'modules/batch/messages'
import { FormField } from 'modules/form'
import {
  findActiveQuantityField,
  getBatchLatestQuantity,
  updateBatchCardQuantity,
} from 'utils/batch'
import { getByPath } from 'utils/fp'
import { encodeId } from 'utils/id'

import BaseCard, { CardAction } from '../BaseCard'

import {
  ApprovalIconStyle,
  BatchNoWrapperStyle,
  BatchTagsWrapperStyle,
  ContainerWrapperStyle,
  DateInputWrapperStyle,
  DividerStyle,
  OrderBatchCardWrapperStyle,
  QuantityWrapperStyle,
  ShipmentWrapperStyle,
  TagsAndTaskWrapperStyle,
  TotalPriceWrapperStyle,
  VolumeWrapperStyle,
  WarehouseArrivalIconStyle,
  WarehouseArrivalWrapperStyle,
} from './style'
import validator from './validator'

type OptionalProps = {
  onClick: (batch: Batch) => void,
  editable: {
    clone: boolean,
    delete: boolean,
    no: boolean,
    quantity: boolean,
    deliveredAt: boolean,
    desiredAt: boolean,
  },
}

type Props = OptionalProps & {
  batch: Batch,
  currency: string,
  price: NewPricePayload,
  saveOnBlur: Function,
  onClone: (batch: Batch) => void,
  onRemove: (batch: Batch) => void,
}

const defaultProps = {
  onClick: () => {},
  editable: {
    clone: false,
    delete: false,
    no: false,
    quantity: false,
    deliveredAt: false,
    desiredAt: false,
  },
}

const OrderBatchCard = ({
  batch,
  onClick,
  onRemove,
  onClone,
  saveOnBlur,
  currency,
  price,
  editable,
  ...rest
}: Props) => {
  const { isOwner } = usePartnerPermission()
  const { hasPermission } = usePermission(isOwner)

  const actions = [
    editable.clone ? <CardAction icon="CLONE" onClick={() => onClone(batch)} /> : null,
    editable.delete ? (
      <BooleanValue>
        {({ value: isOpen, set: dialogToggle }) => (
          <>
            <RemoveDialog
              isOpen={isOpen}
              onRequestClose={() => dialogToggle(false)}
              onCancel={() => dialogToggle(false)}
              onRemove={() => {
                onRemove(batch)
                dialogToggle(false)
              }}
              message={
                <div>
                  <div>
                    <FormattedMessage
                      id="components.cards.deleteBatchItem"
                      defaultMessage="Are you sure you want to delete this Batch?"
                    />
                  </div>
                  <div>
                    <FormattedMessage
                      id="components.cards.deleteBatchItemShipment"
                      defaultMessage="It is being used in a Shipment"
                    />
                  </div>
                </div>
              }
            />
            <CardAction
              icon="REMOVE_ALT"
              hoverColor="RED"
              onClick={() => {
                if (batch.shipment) {
                  dialogToggle(true)
                } else {
                  onRemove(batch)
                }
              }}
            />
          </>
        )}
      </BooleanValue>
    ) : null,
  ].filter(Boolean)

  const {
    id,
    archived,
    no,
    deliveredAt,
    desiredAt,
    packageVolume,
    packageQuantity,
    shipment,
    container,
  } = batch

  const hasContainers = !!container

  const latestQuantity = getBatchLatestQuantity(batch)
  const latestQuantityField: string = findActiveQuantityField({
    [PRODUCED_QUANTITY]: batch?.[PRODUCED_QUANTITY],
    [PRE_SHIPPED_QUANTITY]: batch?.[PRE_SHIPPED_QUANTITY],
    [SHIPPED_QUANTITY]: batch?.[SHIPPED_QUANTITY],
    [POST_SHIPPED_QUANTITY]: batch?.[POST_SHIPPED_QUANTITY],
    [DELIVERED_QUANTITY]: batch?.[DELIVERED_QUANTITY],
  })

  const quantityName = `batches.${id}.${latestQuantityField}`

  const validation = validator({
    no: `batch.${batch.id}.no`,
  })

  const values = {
    [`batch.${batch.id}.no`]: no,
    latestQuantity,
  }

  return (
    <BaseCard
      icon="BATCH"
      color="BATCH"
      showActionsOnHover
      actions={actions}
      isArchived={archived}
      {...rest}
    >
      <div
        className={OrderBatchCardWrapperStyle}
        onClick={() => onClick(batch)}
        role="presentation"
      >
        <div
          className={BatchNoWrapperStyle}
          onClick={(evt) => evt.stopPropagation()}
          role="presentation"
        >
          <FormField name={`batch.${id}.no`} initValue={no} validator={validation} values={values}>
            {({ name: fieldName, ...inputHandlers }) => (
              <TextInputFactory
                {...{
                  ...inputHandlers,
                  onBlur: (evt) => {
                    inputHandlers.onBlur(evt)
                    saveOnBlur({ ...batch, no: inputHandlers.value })
                  },
                }}
                editable={editable.no}
                inputWidth="165px"
                inputHeight="20px"
                inputAlign="left"
                name={fieldName}
                hideTooltip
              />
            )}
          </FormField>
        </div>

        <div
          className={QuantityWrapperStyle}
          onClick={(evt) => evt.stopPropagation()}
          role="presentation"
        >
          <Label required={latestQuantityField === INITIAL_QUANTITY}>
            <FormattedMessage {...messages[latestQuantityField]} />
          </Label>
          <FormField
            name={quantityName}
            initValue={latestQuantity}
            validator={validation}
            values={values}
          >
            {({ name: fieldName, ...inputHandlers }) => (
              <NumberInputFactory
                inputWidth="185px"
                inputHeight="20px"
                editable={editable.quantity}
                {...{
                  ...inputHandlers,
                  onBlur: (evt) => {
                    inputHandlers.onBlur(evt)
                    const newBatch = updateBatchCardQuantity(batch, evt.target.value || 0)
                    saveOnBlur(newBatch)
                  },
                }}
                name={fieldName}
                originalValue={latestQuantity}
                hideTooltip
              />
            )}
          </FormField>
        </div>

        <div
          className={DateInputWrapperStyle}
          onClick={(evt) => evt.stopPropagation()}
          role="presentation"
        >
          <Label>
            <FormattedMessage id="components.cards.delivery" defaultMessage="DELIVERY" />
          </Label>
          <FormField name={`batch.${batch.id}.deliveredAt`} initValue={deliveredAt}>
            {({ name: fieldName, ...inputHandlers }) => (
              <DateInputFactory
                inputWidth="120px"
                inputHeight="20px"
                name={fieldName}
                editable={editable.deliveredAt}
                hideTooltip
                {...{
                  ...inputHandlers,
                  onBlur: (evt) => {
                    inputHandlers.onBlur(evt)
                    saveOnBlur({
                      ...batch,
                      deliveredAt: evt?.target?.value || null,
                    })
                  },
                }}
              />
            )}
          </FormField>
        </div>

        <div
          className={DateInputWrapperStyle}
          onClick={(evt) => evt.stopPropagation()}
          role="presentation"
        >
          <Label>
            <FormattedMessage id="components.cards.desired" defaultMessage="DESIRED" />
          </Label>
          <FormField name={`batch.${batch.id}.desiredAt`} initValue={desiredAt}>
            {({ name: fieldName, ...inputHandlers }) => (
              <DateInputFactory
                inputWidth="120px"
                inputHeight="20px"
                name={fieldName}
                editable={editable.desiredAt}
                hideTooltip
                {...{
                  ...inputHandlers,
                  onBlur: (evt) => {
                    inputHandlers.onBlur(evt)
                    saveOnBlur({
                      ...batch,
                      desiredAt: evt?.target?.value || null,
                    })
                  },
                }}
              />
            )}
          </FormField>
        </div>

        <div className={DividerStyle} />

        <div className={TotalPriceWrapperStyle}>
          <Label>
            <FormattedMessage id="components.cards.total" defaultMessage="TTL PRICE" />
          </Label>
          <Display blackout={!hasPermission(ORDER_ITEMS_GET_PRICE)}>
            <FormattedNumber
              value={(price && price.amount ? price.amount : 0) * latestQuantity}
              suffix={currency}
            />
          </Display>
        </div>

        <div className={VolumeWrapperStyle}>
          <Label>
            <FormattedMessage id="components.cards.volume" defaultMessage="TTL VOL" />
          </Label>
          <Display>
            {packageVolume && packageQuantity !== null && (
              <FormattedNumber
                value={packageVolume.value * (packageQuantity ?? 0)}
                suffix={packageVolume.metric}
              />
            )}
          </Display>
        </div>

        <div className={ShipmentWrapperStyle}>
          <RelateEntity
            link={shipment && shipment.id ? `/new/shipment/${encodeId(shipment.id)}` : ''}
            blackout={isForbidden(shipment)}
            entity="SHIPMENT"
            value={shipment?.__typename === 'Shipment' ? shipment.no : null}
          />
        </div>

        <div className={ContainerWrapperStyle}>
          <RelateEntity
            link={container && container.id ? `/container/${encodeId(container.id)}` : ''}
            blackout={isForbidden(container)}
            entity="CONTAINER"
            value={container?.__typename === 'Container' && container.no}
          />
        </div>

        {hasContainers ? (
          <>
            <div className={WarehouseArrivalWrapperStyle}>
              <div className={WarehouseArrivalIconStyle}>
                <Icon icon="WAREHOUSE" />
              </div>
              <Label>
                <FormattedMessage id="components.cards.agreed" defaultMessage="AGREED" />
              </Label>
              <Display align="left">
                <FormattedDate value={getByPath('warehouseArrivalAgreedDate', container)} />
              </Display>
              <div
                className={ApprovalIconStyle(
                  !!getByPath('warehouseArrivalAgreedDateApprovedBy', container)
                )}
              >
                <Icon icon="CHECKED" />
              </div>
            </div>

            <div className={WarehouseArrivalWrapperStyle}>
              <div className={WarehouseArrivalIconStyle}>
                <Icon icon="WAREHOUSE" />
              </div>
              <Label>
                <FormattedMessage id="components.cards.actual" defaultMessage="ACTUAL" />
              </Label>
              <Display align="left">
                <FormattedDate value={getByPath('warehouseArrivalActualDate', container)} />
              </Display>
              <div
                className={ApprovalIconStyle(
                  !!getByPath('warehouseArrivalActualDateApprovedBy', container)
                )}
              >
                <Icon icon="CHECKED" />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={WarehouseArrivalWrapperStyle}>
              <div className={WarehouseArrivalIconStyle}>
                <Icon icon="WAREHOUSE" />
              </div>
              <Label>
                <FormattedMessage id="components.cards.arrival" defaultMessage="ARRIVAL" />
              </Label>
              <Display align="left">
                <FormattedDate
                  value={getCurrentDate(getByPath('containerGroups.0.warehouseArrival', shipment))}
                />
              </Display>
              <div
                className={ApprovalIconStyle(
                  !!getByPath('containerGroups.0.warehouseArrival.approvedAt', shipment)
                )}
              >
                <Icon icon="CHECKED" />
              </div>
            </div>
            <div className={WarehouseArrivalWrapperStyle} />
          </>
        )}

        <div className={TagsAndTaskWrapperStyle}>
          <div className={BatchTagsWrapperStyle}>
            {batch.tags.length > 0 &&
              batch.tags.flatMap((tag) => (tag.id ? <Tag key={tag.id} tag={tag} /> : []))}
          </div>
        </div>
      </div>
    </BaseCard>
  )
}

OrderBatchCard.defaultProps = defaultProps

export default (withForbiddenCard(OrderBatchCard, 'batch', {
  width: '195px',
  height: '311px',
  entityIcon: 'BATCH',
  entityColor: 'BATCH',
}): any)
