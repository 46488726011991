// @flow

import { useMutation } from '@apollo/client'
import { omit, pick } from 'lodash/fp'
import * as React from 'react'
import { useState } from 'react'
import { cx } from 'react-emotion'
import { useIntl } from 'react-intl'
import { Subscribe } from 'unstated'

import fileUploadMutation from '@graphql/client/document/mutation.fileUpload.graphql'
import type { FilePayload, FileType, DocumentFragment } from '@graphql/server/flow'

import { getFileTypesByEntity } from 'components/Cards/DocumentCard'
import {
  DocumentsUploadWrapperStyle,
  ParentDialogUploadBodyStyle,
} from 'components/Form/DocumentsUpload/style'
import { FormContainer } from 'modules/form'
import { ShipmentInfoContainer } from 'modules/shipment/form/containers'
import { isEquals } from 'utils/fp'
import { uuid } from 'utils/id'
import logger from 'utils/logger'

import useDocumentTypePermission from '../../hooks/useDocumentTypePermission'
import type { UploadFileState } from '../DocumentNavbarContent'
import DocumentTypeArea from '../DocumentTypeArea'

const editableFields = ['id', 'type', 'name', 'path', 'tags', 'memo']
const SELECTED_FIELDS = [
  'id',
  'type',
  'name',
  'path',
  'size',
  'status',
  'tags',
  'memo',
  'tags',
  'entity',
  'createdAt',
  'ownedBy',
  'orphan',
  'iwProUpload',
  '__typename',
]

type Props = {
  isInDialog?: boolean,
  entity: 'Order' | 'OrderItem' | 'Shipment' | 'ProductProvider',
  files: FilePayload[],
  fileViewPreferences: { value: FileType, label: string, show: boolean }[],
  filesState: (
    | DocumentFragment
    | {
        ...UploadFileState,
        tags: [],
        uploading: boolean,
        progress: number,
        isNew: boolean,
      }
  )[],
  fileTypesForHighlight?: FileType[],
  setFileState: Function,
  onSave: (FilePayload[]) => void,
  isMultiSelect: boolean,
  selectedFiles: Object,
  setSelectedFiles: Function,
  canUpload: boolean,
  canAddOrphan: boolean,
  canViewForm: boolean,
  canDownload: boolean,
  canChangeType: boolean,
  canDelete: boolean,
}

const DocumentFileSection = ({
  isInDialog,
  entity,
  files,
  fileViewPreferences,
  filesState,
  fileTypesForHighlight,
  setFileState,
  setSelectedFiles,
  isMultiSelect,
  selectedFiles,
  onSave,
  canUpload,
  canAddOrphan,
  canViewForm,
  canDownload,
  canChangeType,
  canDelete,
}: Props): React.Element<typeof Subscribe> => {
  const intl = useIntl()

  const filesStateRef = React.useRef(filesState)
  const previousFilesRef = React.useRef<UploadFileState[]>([])

  React.useEffect(() => {
    filesStateRef.current = filesState
  }, [filesState])

  if (
    !isEquals(
      (files ?? []).map(pick(editableFields)),
      (previousFilesRef.current ?? []).map(pick(editableFields))
    )
  ) {
    previousFilesRef.current = files.map(pick(SELECTED_FIELDS))
    setFileState(
      files.map(pick(SELECTED_FIELDS)).map((item) => ({
        ...item,
        progress: 100,
        uploading: false,
      }))
    )
  }

  const documentTypePermissions = useDocumentTypePermission({ entity })
  const [changedFiles, setChangedFiles] = useState([])
  const types = getFileTypesByEntity(entity, intl).filter((thisType) =>
    fileViewPreferences.some((goodType) => thisType.value === goodType.value && goodType.show)
  )

  const [upload] = useMutation(fileUploadMutation)

  const handleUpload = (event: SyntheticInputEvent<HTMLInputElement> | File[], type: string) => {
    let newFiles: File[] = []
    setChangedFiles([])

    if (Array.isArray(event)) {
      newFiles = event
    } else {
      event.preventDefault()
      newFiles = Array.from(event.target.files)
    }

    const currentNumberOfFiles = filesState.length

    setFileState([
      ...newFiles.map(({ name }) => ({
        name,
        type,
        id: uuid(),
        path: '',
        memo: '',
        tags: [],
        uploading: true,
        progress: 0,
        isNew: true,
        createdAt: '',
      })),
      ...filesState,
    ])

    Promise.all<any>(
      newFiles.map((file, index) =>
        upload({
          variables: {
            file,
            input: {
              type,
            },
          },
          context: ({
            fetchOptions: {
              onProgress: (progressEvent: ProgressEvent) => {
                const { lengthComputable, loaded, total } = progressEvent
                if (lengthComputable) {
                  setFileState(
                    filesStateRef.current.map((fileState, idx) => ({
                      ...fileState,
                      progress:
                        idx === index + currentNumberOfFiles
                          ? Math.round((loaded / total) * 100)
                          : fileState.progress,
                    }))
                  )
                }
              },
            },
          }: any),
        })
      )
    )
      .then((uploadResults) => {
        setChangedFiles(uploadResults.map(({ data }) => data.fileUpload).filter(Boolean))

        onSave([
          ...uploadResults.map(({ data }) => ({
            ...data.fileUpload,
            uploading: false,
            progress: 100,
            tags: [],
            entity: {
              __typename: entity,
            },
            isNew: true,
          })),
          ...files,
        ])
      })
      .catch((error) => {
        logger.error(error)
        setFileState(
          files.map(pick(SELECTED_FIELDS)).map((item) => ({
            ...item,
            progress: 100,
            uploading: false,
          }))
        )
      })
  }

  const onDocumentClicked = React.useCallback(
    (file: Object) => {
      setSelectedFiles((oldFiles) => {
        if (oldFiles[file.id]) {
          const temp = JSON.parse(JSON.stringify(oldFiles))
          delete temp[file.id]
          return temp
        }

        return {
          ...oldFiles,
          [file.id]: file,
        }
      })
    },
    [setSelectedFiles]
  )

  return (
    <Subscribe to={[FormContainer, ShipmentInfoContainer]}>
      {(
        { state: errors }: FormContainer,
        { state: { ownedBy, connectionBy } }: ShipmentInfoContainer
      ) => (
        <div
          className={cx(DocumentsUploadWrapperStyle, {
            [ParentDialogUploadBodyStyle]: isInDialog,
          })}
        >
          {types.map((type) => {
            const canSetType = documentTypePermissions[type.value]?.canSet

            return (
              <DocumentTypeArea
                key={type.value}
                entityType={entity}
                entityOwnedById={ownedBy.__typename === 'Organization' ? ownedBy.id : null}
                entityConnectionById={
                  connectionBy?.__typename === 'Connection' ? connectionBy.id : null
                }
                // $FlowFixMe
                type={type}
                types={types.map((t) => t.value)}
                allFiles={filesState}
                files={filesState.filter((file) => file.type === type.value)}
                fileErrors={errors.serverErrors.files ?? {}}
                areaHighlight={fileTypesForHighlight?.some((file) => file === type.value) || false}
                filesForHighlight={changedFiles}
                scrollOnFileHighlight
                onSave={(updatedValues, changedFile) => {
                  onSave([
                    ...updatedValues.map((f) => omit(['uploading', 'progess'], f)),
                    ...files.filter((f) => f.type && f.type !== type.value),
                  ])
                  setChangedFiles([changedFile].filter(Boolean))
                }}
                onUpload={(evt) => handleUpload(evt, type.value)}
                canUpload={canUpload || canSetType}
                canAddOrphan={canAddOrphan || canSetType}
                canViewForm={canViewForm}
                canDownload={canDownload}
                canChangeType={canChangeType || canSetType}
                canDelete={canDelete || canSetType}
                isMultiSelect={isMultiSelect}
                selectedFiles={selectedFiles}
                onDocumentClicked={onDocumentClicked}
              />
            )
          })}
        </div>
      )}
    </Subscribe>
  )
}

export default DocumentFileSection
