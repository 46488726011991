import { initializeApp } from 'firebase/app'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'

const firebaseApp = initializeApp({
  apiKey: process.env.ZENPORT_FIREBASE_API_KEY || '',
  databaseURL: `https://${process.env.ZENPORT_FIREBASE_PROJECT_ID || ''}.firebaseio.com`,
  projectId: process.env.ZENPORT_FIREBASE_PROJECT_ID || '',
  authDomain: process.env.ZENPORT_FIREBASE_AUTH_DOMAIN || '',
  storageBucket: process.env.ZENPORT_FIREBASE_STORAGE_BUCKET || '',
  messagingSenderId: process.env.ZENPORT_FIREBASE_MESSAGING || '',
  appId: process.env.ZENPORT_FIREBASE_APP_ID || '',
})

initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider(process.env.ZENPORT_RECAPTCHA_SITE_KEY),
  isTokenAutoRefreshEnabled: true,
})

export default firebaseApp
