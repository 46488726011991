// @flow
import 'react-toastify/dist/ReactToastify.css'
import './styles/reset.css'
import '@ag-grid-community/styles/ag-grid.css'
import '@ag-grid-community/styles/ag-theme-alpine.css'
import 'tippy.js/dist/tippy.css'

import { ApolloProvider as ApolloHookProvider } from '@apollo/client'
import { dom } from '@fortawesome/fontawesome-svg-core'
import NP from 'number-precision'
import React, { Profiler } from 'react'
import { ApolloProvider } from 'react-apollo'
import { hydrate, render } from 'react-dom'
import { ToastContainer } from 'react-toastify'
import { unstable_trace as trace } from 'scheduler/tracing'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import UNSTATED from 'unstated-debug'

import apolloClient from '@apollo-client'
import { SideBarProvider } from '@modules/SideBar/hooks/useSideBar'
import { LanguageProvider } from '@modules/app/language'
import loadFonts from '@pages/_fonts'
import { GlobalStyle } from '@styles/global-style'
import { theme } from '@styles/theme'

import ViewerProvider from 'contexts/Auth'
import PermissionsProvider from 'contexts/Permissions'

import DeployHandler from './components/DeployHandler'
import NotificationsProvider from './contexts/Notifications'
import errorReport from './errorReport'
import { useFilterSortInvalidator } from './hooks/useFilterSort'
import Routes from './routes'
import * as serviceWorker from './serviceWorker'
import { isAppInProduction, isAppInStaging, isEnableStrictMode } from './utils/env'
import logger from './utils/logger'

loadFonts()
errorReport()

UNSTATED.isEnabled = !isAppInProduction

NP.enableBoundaryChecking(isAppInProduction)

const container = document.querySelector('#root')

if (!container) {
  throw new Error(`couldn't find element with id root`)
}

const AppHooks = () => {
  useFilterSortInvalidator()
  return null
}

const GlobalStyles = createGlobalStyle`
    ${dom.css()}
`

const renderApp = (Component, renderFn) => {
  trace('initial render', performance.now(), () =>
    renderFn(
      <Profiler id="Application" onRender={logger.debug}>
        <div>
          <ApolloHookProvider client={apolloClient}>
            <ApolloProvider client={apolloClient}>
              <ThemeProvider theme={theme}>
                <>
                  <GlobalStyle />
                  <ViewerProvider>
                    <PermissionsProvider>
                      <LanguageProvider>
                        <NotificationsProvider>
                          <SideBarProvider>
                            <GlobalStyles />
                            <AppHooks />

                            {(isAppInProduction || isAppInStaging) && (
                              <DeployHandler
                                revision={process.env.ZENPORT_FIREBASE_DEPLOY_REVISION || ''}
                                revisionKey={process.env.ZENPORT_FIREBASE_REVISION_KEY || ''}
                              />
                            )}
                            {isEnableStrictMode ? (
                              <React.StrictMode>
                                <Component />
                              </React.StrictMode>
                            ) : (
                              <Component />
                            )}
                          </SideBarProvider>
                        </NotificationsProvider>
                      </LanguageProvider>
                    </PermissionsProvider>
                  </ViewerProvider>
                </>
              </ThemeProvider>
            </ApolloProvider>
          </ApolloHookProvider>

          <ToastContainer />
        </div>
      </Profiler>,
      container
    )
  )
}

if (container.hasChildNodes()) {
  renderApp(Routes, hydrate)
} else {
  renderApp(Routes, render)
}

serviceWorker.register({
  onUpdate: (registration) => {
    // TODO: notify our client with toast
    logger.warn(
      'New content is available and will be used when all tabs for this page are closed',
      registration
    )
    // manual update, refer https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle#manual_updates
    registration.update()
  },
  onSuccess: (registration) => {
    logger.warn('Content is cached for offline use.', registration)
  },
})
