// @flow
import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import Display from '@components/Form/Display'
import { TAG_GET } from '@modules/permission/constants/tag'

import { BaseButton } from 'components/Buttons'
import { getFileTypesByEntity } from 'components/Cards/DocumentCard'
import {
  FieldItem,
  Label,
  LastModified,
  SectionHeader,
  SelectInputFactory,
  TextAreaInputFactory,
} from 'components/Form'
import { computeIcon, getFileExtension, getFileName } from 'components/Form/DocumentsUpload/helpers'
import TagsInput from 'components/Form/TagsInput'
import FormattedBytes from 'components/FormattedBytes'
import GridColumn from 'components/GridColumn'
import Icon from 'components/Icon'
import { Section } from 'components/Sections'
import { Tooltip } from 'components/Tooltip'
import { useEntityHasPermissions } from 'contexts/Permissions'
import DocumentFormContainer from 'modules/document/form/container'
import { FormField } from 'modules/form'
import validator from 'modules/tableTemplate/form/validator'
import { canDownloadFile, canUpdateFile } from 'utils/file'

import { FileIconStyle, FileInfoWrapperStyle, FileNameStyle, FileNameWrapperStyle } from './style'

const DocumentSection = (): React.Node => {
  const { state, originalState, setFieldValue } = DocumentFormContainer.useContainer()
  const intl = useIntl()

  const hasPermissions = useEntityHasPermissions(state.entity)
  const canUpdate = canUpdateFile(hasPermissions, state.entity?.__typename)
  const isPrivateBoardDocument = !!state.messageGroup
  const canDownload = canDownloadFile(hasPermissions, state.entity?.__typename)

  const getFormFieldProps = (name: string) => {
    return {
      name,
      initValue: state[name],
      validator,
      values: state,
      setFieldValue,
    }
  }

  const fileExtension = getFileExtension(state.name)
  const fileName = getFileName(state.name)
  const fileIcon = computeIcon(fileExtension)

  const fileTypeOptions = getFileTypesByEntity(state.entity?.__typename, intl)

  const organizationIds = [state.ownedBy?.id]?.filter(Boolean)

  const documentParentTypename = state.entity && state.entity.__typename
  const documentParentTypenameCamelCase =
    documentParentTypename &&
    `${state.entity.__typename[0].toLowerCase()}${state.entity.__typename.slice(1)}`
  const documentParentConnectionId =
    documentParentTypenameCamelCase && state[documentParentTypenameCamelCase]?.connectionBy.id

  return (
    <>
      <SectionHeader
        icon="DOCUMENT"
        title={
          <FormattedMessage id="modules.Documents.documentSection" defaultMessage="Document" />
        }
      >
        {state.updatedAt && state.updatedBy && (
          <>
            <LastModified updatedAt={state.updatedAt} updatedBy={state.updatedBy} />
          </>
        )}
      </SectionHeader>

      <Section>
        <GridColumn>
          <FormField {...getFormFieldProps('type')} saveOnChange>
            {({ value, ...inputHandlers }) => (
              <SelectInputFactory
                value={fileTypeOptions ? value : null}
                {...inputHandlers}
                label={<FormattedMessage id="modules.Documents.type" defaultMessage="Type" />}
                editable={canUpdate && !isPrivateBoardDocument}
                originalValue={originalState.type}
                required
                items={fileTypeOptions ?? []}
              />
            )}
          </FormField>
          <FieldItem
            vertical
            label={
              <Label height="30px">
                <FormattedMessage id="modules.Documents.tags" defaultMessage="Tags" />
              </Label>
            }
            input={
              <TagsInput
                name="tags"
                tagType="File"
                connectionIds={documentParentConnectionId ? [documentParentConnectionId] : []}
                organizationIds={organizationIds}
                values={state.tags}
                onChange={(value) => {
                  setFieldValue('tags', value)
                }}
                onClickRemove={({ id: tagToRemoveId }) => {
                  setFieldValue(
                    'tags',
                    state.tags.filter(({ id }) => id !== tagToRemoveId)
                  )
                }}
                shouldConfirmRemove={({ connectionBy: { id: tagToRemoveConnectionId } }) =>
                  tagToRemoveConnectionId !== documentParentConnectionId
                }
                confirmRemoveDialogMessage={
                  <FormattedMessage
                    id="modules.Connection.warnCannotReadd"
                    defaultMessage="No matching connection found for this item! If you remove it, you will not be able to readd it. Would you still like to continue?"
                  />
                }
                editable={{
                  set: !!documentParentConnectionId && hasPermissions(TAG_GET) && canUpdate,
                  remove: canUpdate,
                }}
              />
            }
          />

          <FormField {...getFormFieldProps('memo')}>
            {(inputHandlers) => (
              <TextAreaInputFactory
                {...inputHandlers}
                label={
                  <FormattedMessage
                    id="modules.Documents.description"
                    defaultMessage="Description"
                  />
                }
                editable={canUpdate}
                originalValue={originalState.memo}
                inputWidth="400px"
              />
            )}
          </FormField>
        </GridColumn>

        <div className={FileInfoWrapperStyle}>
          <GridColumn gap="10px">
            <div className={FileIconStyle(fileIcon.color)}>
              <Icon icon={fileIcon.icon} />
            </div>

            <FieldItem
              label={
                <Label width="80px">
                  <FormattedMessage id="modules.Documents.filename" defaultMessage="Filename" />
                </Label>
              }
              input={
                <Tooltip message={`${fileName}.${fileExtension}`}>
                  <div className={FileNameWrapperStyle}>
                    <div className={FileNameStyle}>{fileName}</div>
                    {`.${fileExtension}`}
                  </div>
                </Tooltip>
              }
            />

            <FieldItem
              label={
                <Label width="80px">
                  <FormattedMessage id="modules.Documents.fileSize" defaultMessage="File Size" />
                </Label>
              }
              input={
                <Display>
                  <FormattedBytes value={state.size} />
                </Display>
              }
            />
          </GridColumn>

          <BaseButton
            label={<FormattedMessage id="modules.Documents.download" defaultMessage="Download" />}
            icon="DOWNLOAD"
            textColor="GRAY_DARK"
            hoverTextColor="TEAL"
            backgroundColor="GRAY_SUPER_LIGHT"
            hoverBackgroundColor="GRAY_VERY_LIGHT"
            disabled={!canDownload}
            onClick={() => {
              window.open(state.path, '_blank')
            }}
          />
        </div>
      </Section>
    </>
  )
}
export default DocumentSection
