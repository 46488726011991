// @flow
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import Display from '@components/Form/Display'
import type { File } from '@graphql/server/flow'

import { BaseButton } from 'components/Buttons'
import { Blackout, SectionHeader } from 'components/Form'
import { SingleCardSection } from 'components/Sections'
import { useHasPermissions } from 'contexts/Permissions'
import DocumentFormContainer from 'modules/document/form/container'
import entityHasConnection from 'utils/entityHasConnection'
import { canChangeFileParent } from 'utils/file'
import { toLowerFirst } from 'utils/string'

import ParentDocumentSelection from '../ParentDocumentSelection'

import {
  ParentEndProductCard,
  ParentItemCard,
  ParentOrderCard,
  ParentShipmentCard,
} from './components'

const ParentSection = (): React.Node => {
  const { state, originalState, setFieldValues } = DocumentFormContainer.useContainer()
  const hasPermissions = useHasPermissions(
    originalState?.ownedBy?.id,
    originalState?.connectionBy?.id,
    originalState?.type !== undefined && !entityHasConnection(originalState?.type)
  )

  const isPrivateBoardDocument = !!state.messageGroup
  const canSetParent = canChangeFileParent(hasPermissions, originalState) && !isPrivateBoardDocument

  const [isParentSelectionOpen, setParentSelectionOpen] = React.useState(false)

  const mapping = {
    Order: <ParentOrderCard order={state?.order} />,
    OrderItem: <ParentItemCard orderItem={state?.orderItem} />,
    Shipment: <ParentShipmentCard shipment={state?.shipment} />,
    ProductProvider: <ParentEndProductCard productProvider={state?.productProvider} />,
  }

  const onSelectDone = ({
    parent,
    files,
    activeType,
  }: {
    /* eslint-disable */
    parent: Object,
    files: File[],
    activeType: string,
    /* eslint-enable */
  }) => {
    if (!parent || !parent.id) {
      return
    }

    const newType = {
      id: parent.id,
      __typename: activeType,
    }

    const activePropertyType = toLowerFirst(activeType)

    const newTypes = {
      entity: newType,
      order: newType,
      orderItem: newType,
      productProvider: newType,
      shipment: newType,
      [activePropertyType]: {
        ...parent,
        files,
      },
    }

    setFieldValues(newTypes)
    setParentSelectionOpen(false)
  }

  return (
    <>
      <SectionHeader
        icon="PARENT"
        title={<FormattedMessage id="modules.Documents.parentSection" defaultMessage="Parent" />}
      />

      <SingleCardSection
        navbarContent={
          canSetParent && (
            <BaseButton
              label={
                <FormattedMessage id="modules.Documents.setParent" defaultMessage="Set Parent" />
              }
              onClick={() => setParentSelectionOpen(true)}
              backgroundColor="TEAL"
              hoverBackgroundColor="TEAL_DARK"
            />
          )
        }
      >
        {state.entity ? (
          mapping[state.entity?.__typename] || <Blackout width="195px" height="230px" />
        ) : (
          <Display align="center">
            <FormattedMessage
              id="modules.Documents.noParentMessage"
              defaultMessage="This file does not belong to any parent. You can add this file to a parent in the parent's form"
            />
          </Display>
        )}
      </SingleCardSection>

      <ParentDocumentSelection
        isParentSelectionOpen={isParentSelectionOpen}
        onSelectDone={onSelectDone}
        files={state}
        onRequestClose={() => setParentSelectionOpen(false)}
      />
    </>
  )
}

export default ParentSection
