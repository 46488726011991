// @flow
import Tippy from '@tippyjs/react'
import * as React from 'react'

type OptionalProps = {|
  enabled?: boolean,
  delay?: number | [number, number],
  distance?: number,
  maxWidth?: number,
  visible?: boolean,
  placement?: 'top' | 'bottom' | 'left' | 'right',
  popperOptions?: { positionFixed: boolean },
|}

export type Props = {|
  ...OptionalProps,
  ...{|
    message: React.Node | string,
    children: React.Node | string,
  |},
|}

export default function Tooltip({
  message,
  children,
  enabled,
  delay,
  distance,
  visible,
  maxWidth,
  ...rest
}: Props): string | React.Node {
  if (!message) {
    return children
  }

  return (
    <Tippy
      content={message}
      arrow
      delay={delay || 200}
      ignoreAttributes
      visible={visible}
      {...(maxWidth ? { maxWidth } : {})}
      {...rest}
    >
      {children}
    </Tippy>
  )
}
