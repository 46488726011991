// @flow
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import Display from '@components/Form/Display'
import UserAvatar from '@components/UserAvatar'
import type { UserCardFragment } from '@graphql/server/flow'

import FormattedName from 'components/FormattedName'
import Icon from 'components/Icon'
import RelateEntity from 'components/RelateEntity'
import Tag from 'components/Tag'
import withForbiddenCard from 'hoc/withForbiddenCard'

import BaseCard from '../BaseCard'

import {
  UserCardWrapperStyle,
  UserEmailStyle,
  UserNameStyle,
  UserRoleIconStyle,
  UserRoleStyle,
  UserTagsWrapperStyle,
} from './style'

type Props = {
  user: UserCardFragment,
  onClick?: Function,
  actions?: React.Node[],
}

const MANAGER = 'MANAGER'
const USER = 'USER'

const UserCard = ({ user, onClick = () => {}, actions = [], ...rest }: Props) => {
  const { firstName, lastName, roles, email, tags, organization } = user

  const userRoleIcon = roles.some(({ name = '' }) => name.includes('admin')) ? MANAGER : USER

  return (
    <BaseCard icon="USERS" color="USERS" actions={actions} {...rest}>
      <div className={UserCardWrapperStyle} onClick={onClick} role="presentation">
        <UserAvatar
          firstName={firstName}
          lastName={lastName}
          width="105px"
          height="105px"
          showBothInitials
          hideTooltip
        />

        <div className={UserNameStyle}>
          <FormattedName firstName={firstName} lastName={lastName} />
        </div>

        <div className={UserEmailStyle}>{email}</div>

        <div className={UserRoleStyle}>
          <div className={UserRoleIconStyle}>
            <Icon icon={userRoleIcon} />
          </div>
          <Display>
            {userRoleIcon === 'MANAGER' ? (
              <FormattedMessage id="components.cards.managerUser" defaultMessage="Manager" />
            ) : (
              <FormattedMessage id="components.cards.defaultUser" defaultMessage="User" />
            )}
          </Display>
        </div>

        <RelateEntity link="" entity="PARTNER" value={organization?.name ?? ''} width="150px" />

        <div className={UserTagsWrapperStyle}>
          {tags.length > 0 && tags.map((tag) => <Tag key={tag.id} tag={tag} />)}
        </div>
      </div>
    </BaseCard>
  )
}

export default (withForbiddenCard(UserCard, 'user', {
  width: '195px',
  height: '239px',
  entityIcon: 'User',
  entityColor: 'User',
}): any)
